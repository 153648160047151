@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --main-bg: #060054;
  --main-color: #2f55d4;
  --alt-color: #2cc4b3;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px
}

body {
  font-size: .9rem;
  color: #a0a9b1;
  font-family: Rubik, sans-serif;
}

.bg-light{
  background-color: #f8fafe;
}

.bg-main{
  background-color: var(--main-color);
}

.text-main{
  color: var(--main-color);
}

.bg-main-alt{
  background-color: var(--alt-color);
}

.text-main-alt{
  color: var(--alt-color);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.heading{
  font-weight: 700;
  font-size: 38px;
  line-height: 1.4;
  color: #060054;
}

nav .btn-main,nav .btn-main-alt{
  /* padding: 0.5rem 1rem 0.5rem 1rem; */
}

input,button,textarea,select{
  outline: none;
}

a{
  text-decoration: none;
}

.btn {
  font-size: .9rem;
  padding: 0.7rem 1.4rem;
  font-weight: 500;
  transition: all .4s;
  border-radius: 7px;
}

.btn:hover {
  transform: translateY(-4px);
  outline: none;
  text-decoration: none;
}

.btn-main,.btn-main-alt,.btn-main-inverse{
  display: inline-block;
  padding: 1rem 1.6rem;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.25px;
  border-radius: 8px;
  transition: .25s;
  line-height: normal;
}

.btn-main{
  color: #fff;
  background: #2f55d4;
  border-color: #2f55d4;
  box-shadow: 0 8px 14px #2f55d433;
}

.btn-main:hover{
  box-shadow: 0 16px 31px -16px #2f55d4;
  background: #294dc6;
  border-color: #294dc6;
}

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.small-title {
  letter-spacing: 1px;
  font-size: 14px;
  color: #2f55d4;
  text-transform: uppercase;
}

.btn-main-alt{
  color: #fff;
  background: #34D1BF;
  border-color: #34d1bf;
  box-shadow: 0 8px 14px #34d1bf33;
}

.btn-main-alt:hover{
  box-shadow: 0 16px 31px -16px #34d1bf;
  background: #2cc4b3;
  border-color: #2cc4b3;
}


.btn-main-inverse{
  background: #161616;
  color: var(--main-color);
}

.btn-main-inverse:hover{
  background: #000;
  --tw-shadow-color: #000;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

h1, h2, h3, h4, h5, h6 {
  color: #343a40;
  font-weight: 500;
}
.h1, h1 {
  font-size: calc(1.375rem + 1.5vw);
}
.h4, h4 {
  font-size: calc(1.275rem + .3vw);
}
.h5, h5 {
  font-size: 1.25rem;
}

p {
  line-height: 1.8;
  font-size: 15px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (max-width: 768px) {
  .hide-in-sm{
    display: none;
  }
  .heading {
    font-size: 32px !important;
    line-height: 48px;
  }
  main{
    overflow-x: hidden;
  }
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px){
  .container {
      max-width: 1140px;
  }
}

@media only screen and (min-width: 920px) {
    .container{
      /* padding-left: 34px;
      padding-right: 34px; */
    }

    nav .container{
      /* padding-left: 38px;
      padding-right: 38px; */
    }
}