.main-home{
    background-size: cover;
    background-repeat: no-repeat;

    .bg-overlay{
        background-color: #23252fb9;
        padding-top: 300px;
    }
}


.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: black;
    padding: 0px;
    border-radius: 8px;
    max-width: 90%;
    width: 800px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    align-self: flex-end;
  }
  
  video {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  

@media only screen and (max-width: 1025px) {
    .main-home{
        padding-top: 110px;
        background-position: center center;
    }
}

.main p{
    line-height: 1.8;
    font-size: 18px;
    margin-bottom: 1rem;
    margin-top: 0;
}

section.whyus{

}

section.whyus .grid{
    text-align: center;
}

section.whyus .grid span{
    align-items: center;
    background-color: #2f55d41a;
    color: #fff;
    display: flex;
    font-weight: 500;
    justify-content: center;
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    margin: auto;
}
section.whyus .grid span svg{
    color: #2f55d4;
    fill: #2f55d433;
}
section.whyus .grid h5{
    font-size: 18px;
    margin-top: 1.5rem;
}
section.whyus .grid p{
    margin-bottom: 0;
}

section#features{
}

section#features .features-list h5{
    font-size: 1.5rem;
}

section#keyfeatures{
    color: #ffffff80;

    h5{
        font-size: 1.4rem;
        color: white;
    }

    span.badge-alt{
        padding: 1.25rem;
        display: inline-block;
        border-radius: 50%;
        color: white;
    }

    p, ul{
        font-size: 1rem;
    }
}


.whychooseusx{
    background: url('https://images.unsplash.com/photo-1528731708534-816fe59f90cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8bWluaW1hbCUyMGJhY2tncm91bmR8ZW58MHx8MHx8fDA%3D&w=1000&q=80');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.badge-alt {
    align-items: center;
    background: rgba(52, 209, 191, 0.2);
    display: flex;
    font-weight: 500;
    justify-content: center;
    border-radius: 30px;
    padding: 1rem 1.5rem;
}

.badge-rounded {
    align-items: center;
    background-color: #2f55d41a;
    display: flex;
    font-weight: 500;
    justify-content: center;
    border-radius: 50%;
    height: 3.5rem;
    width: 3.5rem;
}

.badge-rounded svg {
    color: #2f55d4;
    fill: #2f55d433;
}

section#clients .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    padding: 9px;
    width: 32px;
    height: 32px;
    background: rgba(47, 85, 212, .2);
    color: #2f55d4;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    margin-left: 10px;
}
section#clients .owl-nav [class*='owl-']:hover {
    background: #2f55d4;
    color: #fff;
}

section#clients .owl-carousel .owl-stage{display: flex;}

section#clients .owl-carousel .owl-item .item{
    display: flex;
    flex: 1 0 auto;
    height: 100%;
}
section#clients .owl-carousel .owl-item .item > div{
    box-shadow: 0 8px 35px #4950570d;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 1.5rem;
    color: #212529;
    background: #FFF;
}
section#clients .owl-carousel .owl-item .item p{
    color: #212529;
}
section#clients .owl-carousel .owl-item .item .user-details div:nth-child(1){
    font-weight: 500;
}
section#clients .owl-carousel .owl-item .item .user-details div:nth-child(2){
    color: #555;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #ffffff !important;
    border: 2px solid #2f55d4;
}

section#clients .client-images img {
    max-height: 94px;
    width: auto !important;
    margin: 10px auto;
    opacity: .6;
    transition: all .25s;
}

section#clients .client-images img:hover {
    opacity: 1;
}

section#plans{

}

section#plans .pills{

}

section#plans .pills button{
    border-radius: 0.4rem;
    color: #a0a9b1;
    padding: 0.6rem 1.4rem;
    font-weight: 500;
    font-size: 14px;
}
section#plans .pills button.active{
    background-color: #2f55d4;
    color: #FFF;
}


section#plans div.plan{
    box-shadow: 0 8px 35px #4950570d;
    border-radius: 10px;
    text-align: left;
    flex: 1 1 auto;
    padding: 2.5rem 2.5rem;
    background-color: #FFF;
    max-width: 415px;
    margin: 0 auto;
    position: relative;
}

section#plans div.plan h5{
    font-size: 1.1rem;
    margin-bottom: .8rem;
}

section#plans div.plan p{
    font-size: 13px;
    height: 36px;
    line-height: 1.5;
}

section#plans div.plan div.price{
    color: #343a40;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
section#plans div.plan div.price span:nth-child(1){
    font-size: calc(2rem);
}
section#plans div.plan div.price span:nth-child(2){
    font-size: 15px;
}

section#plans div.plan a.btn-main{
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
    font-weight: 400;
    padding: .85rem .85rem;
}
section#plans div.plan ul{
    text-align: left;
}
section#plans div.plan ul li{
    color: #444;
    font-weight: 400;
    position: relative;
}

section#plans div.plan ul li span{
    margin-left: 26px;
    display: block;
}

section#plans div.plan ul li svg{
    color: rgb(34 197 94);
    margin-top: 3px;
    font-size: 12px;
    position: absolute;
    // left: -24px;
    top: 1px;
}


section#newsletter input{
    height: 48.65px;
    border-radius: 7px;
    font-size: 15px;
    box-shadow: none;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #FFF;
    border: 1px solid #dee2e6;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
section#newsletter input:focus {
    border-color: #2f55d4;
}

section#terms ul li {
    margin-bottom: 15px;
}

section#terms h2 {
    margin-top: 40px;
    margin-bottom: 15px;
    font-size: 1.75rem;
    font-weight: bold;
}
section#terms p {
    margin-bottom: 1rem;
}
section#terms ol {
    margin-top: 2.5rem;
}

@media only screen and (min-width: 1024px) {
    section#plans div.plan{
        width: 100% !important;
        max-width: 100% !important;
    }
}